<script>
    import 'o365.dataObject.extension.ImportData.ts';
</script>
<script setup>
    import {popScopeId, ref,useAttrs} from 'vue';
    import Progress from 'o365.vue.components.FileUploadProgress.vue';
    import vDropZone from 'o365.vue.directive.uploadDropZone.js';
    const fileInput = ref(null);
    const progressModal = ref(null);

    const props = defineProps({
        /** Dataobject that will be used when uploading files */
        dataObject: {
            required: false
        },
        /** Specifies if the FileUpload should disable the default upload button
         *  Only be used if no template is added to the default slot
         */
        disabled: {
            type: Boolean,
            required: false,
            default: false
        },
        beforeUpload: {
            type: Function,
            required: false,
            default: null
        },
        text:{
            type:String,
            required:false,
            default:"Upload"
        },
        hideLoading:{
            type: Boolean,
            required: false,
            default: false
        },
        hideProgressBar:{
            type: Boolean,
            required: false,
            default: false
        },
        showDropZone:{
            type:Boolean,
            required: false,
            default: false
        },
        primKey:{
            type: String,
            required: false,
            default: null
        },
        btnClass:{
            type: String,
            required: false,
            default: "btn btn-primary btn-sm"
        },
        customBtnMarkup:{
            type:Boolean,
            required: false,
            default: false
        },
        routeUrl:{
            type:String,
            required: false,
        },
        disableChunks:{
            type:Boolean,
            required: false,
            default: false
        }
    });


    let classList = "btn btn-primary btn-sm";
    const emit = defineEmits(['uploaded','error']);
    const attrs = useAttrs();
    const progress = ref({
        total:0,
        progress:0

    })
    if(attrs.hasOwnProperty("class")){
        classList = attrs['class'];
    }

    const uploadCompleted= ()=>{
        if(progressModal.value && progress.value && !progress.value.error){
            setTimeout(()=>{
                console.log("Closing modal");
                progressModal.value.modal.hide();
            },60)
            
        }
        
        fileInput.value.value = "";

        emit('uploaded');
    }
     const uploadError= ()=>{
       
            setTimeout(()=>{
          
                progressModal.value.modal.hide();
            },60)
            
    
        
        fileInput.value.value = "";

        emit('error');
    }

    const fileUploadProgress= (pProgress)=>{
        progress.value = pProgress;
       
        if(progressModal.value && !pProgress.completed){
      
            progressModal.value.modal.show();
        }

    }
   

    const openFileUpload=()=>{
        fileInput.value.click();
    }
    const fileInputChange =()=>{
        const vOptions = getOptions();
        vOptions.files = fileInput.value.files;
        props.dataObject.importData.upload(vOptions,props.primKey?{PrimKey:props.primKey}:null);
    }


    const getOptions = ()=>{
        if(props.routeUrl){
            props.dataObject.importData.url = props.routeUrl;
        }
        if(props.disableChunks){
            props.dataObject.importData.useChunks = false;
        }
        return {
            beforeUpload:props.beforeUpload,
            onProgress:fileUploadProgress,
            onCompleted:uploadCompleted,
            onError:uploadError
        }
    }

    const onDrop = (e)=>{
        const vOptions = getOptions();
        vOptions.files = e.dataTransfer.files;
      
        props.dataObject.importData.upload(vOptions,props.primKey?{PrimKey:props.primKey}:null);
      
        e.target.classList.remove("dropzone");
        e.preventDefault();
    }
    const onOver = (e)=>{
        e.preventDefault();
    }
    const onEnter = (e)=>{
        e.target.classList.add("dropzone");
        e.preventDefault();
    }
    const onLeave = (e)=>{
        e.target.classList.remove("dropzone");
        e.preventDefault();
    }

    function getTitle() {
        if (!props.dataObject.allowInsert || !props.dataObject.allowUpdate) {
            return 'Upload is disabled...'
        }
        return 'Upload a file...'
    }


</script>
<template>
    <!--<div v-file-upload-btn="dataObject" > -->
    
    <span @click="openFileUpload" v-bind="$attrs">
        <template v-if="!showDropZone && customBtnMarkup">
            <slot>{{$t(text)}}</slot>
        </template>
        <template v-if="!showDropZone && !customBtnMarkup">
            <slot>
                <button :class="btnClass" :disabled = "dataObject.importData.uploading == true || !dataObject.allowInsert || !dataObject.allowUpdate" :title="getTitle()">
                    <div class="spinner-border spinner-border-sm me-1" title="$t('Uploading...')" role="status" v-if="dataObject.importData.uploading && !hideLoading"></div>
                    {{$t(text)}}
                </button>
            </slot>
        </template>
        <div v-if="showDropZone" class="position-relative" @dragleave="onLeave"  @dragenter="onEnter" @dragover="onOver" @drop.prevent="onDrop"><slot>{{$t("Drop files here")}}</slot></div>
        <input v-bind="$attrs" type="file" :disabled = "dataObject.importData.uploading == true || disabled" ref="fileInput" class="d-none" @change="fileInputChange">
        <Progress :fileUpload = "dataObject.importData" :progress="progress" v-if=" !hideProgressBar" ref="progressModal"></Progress>    
    </span>
    
</template>
<style>
     .dropzone:after {
        content: "Drop files here...";
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        inset: 0;
        background-color: rgba(255, 255, 255, .8);
        border: 'dashed grey 4px';
        z-index: 1000;
    }
</style>